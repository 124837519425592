<template>
  <div class="container">
    <div class="top_box">
      <common-hearder />
      <img class="home_banner" src="@/assets/images/home.png" />
      <div class="main_title">让跨境出海服务---更简单!</div>
      <div class="sub_title">Make cross-border sea service easier!</div>
    </div>
    <product-info />
    <more-trademark />
    <strength-info />
    <partner-info />
    <News />
    <Footer />
    <!-- <follow-wechat  class="follow_item" /> -->
    <!-- <follow-menu  class="follow_right" /> -->
  </div>
</template>

<script>
import CommonHearder from '@/components/commonHearder.vue'
import ProductInfo from './components/ProductInfo.vue'
import MoreTrademark from './components/MoreTrademark.vue'
import StrengthInfo from './components/StrengthInfo.vue'
import PartnerInfo from './components/PartnerInfo.vue'
import News from './components/News.vue'
import Footer from './components/Footer.vue'
import FollowWechat from './components/FollowWechat.vue'
import FollowMenu from './components/FollowMenu.vue'
export default {
  name: 'HomeView',
  components: {
    CommonHearder,
    ProductInfo,
    MoreTrademark,
    StrengthInfo,
    PartnerInfo,
    News,
    Footer,
    FollowWechat,
    FollowMenu,
  },
  data() {
    return {
    }
  },
  mounted() {

  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.container {
  color: #3a3a3b;
  background-color: #f3f3f3;
}
.top_box {
  background-color: white;
  padding-bottom: 0.4rem;
}

.home_banner {
  width: 100%;
  height: 100%;
}
.main_title {
  text-align: center;
  font-weight: bold;
  font-size: 0.4rem;
  margin-top: 0.2rem;
}
.sub_title {
  font-size: 0.28rem;
  text-align: center;
  color: #757679;
  margin-top: 0.08rem;
}
.tab_menu .van-tab__text {
  // .van-tabs__nav
  // .van-tab
  // .van-tab__text
  font-weight: bold;
}
.tab_menu {
  font-weight: bold;
}
.follow_item {
  position: fixed;
  bottom: 0;
  z-index: 10;
}
.follow_right {
  position: fixed;
  right: 0;
  z-index: 10;
  bottom: 2rem;
}
::v-deep .van-tab {
  font-size: 0.28rem;
  line-height: 0.6rem;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 0.8rem;
}
::v-deep .van-tab--active {
  font-weight: bold;
}
</style>
